<template lang="html">
  <div id="home-page">

    <section class="banner-section" data-aos="fade">
      <swiper class="swiper desktop" :options="bannersOptions">
        <swiper-slide v-for="(b, bhInx) in banners.desktop" :key="'bhInx-'+bhInx">
          <div class="placed-backg box" v-bind:style="{ backgroundImage: 'url('+b.imageUrl+')' }">
            <img src="public/images/pages/home/banner.png">
          </div>
        </swiper-slide>

        <!-- <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div> -->
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>

      <swiper class="swiper mobile" :options="bannersOptions">
        <swiper-slide v-for="(b, bhmInx) in banners.mobile" :key="'bhmInx-'+bhmInx">
          <div class="placed-backg box" v-bind:style="{ backgroundImage: 'url('+b.imageUrl+')' }">
            <img src="public/images/pages/home/banner-m.png">
          </div>
        </swiper-slide>

        <!-- <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div> -->
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </section>

    <section class="container policies-section">
      <div class="row justify-content-center">
        <div class="col-12 col-title">
          <h2 class="title-s1">Nuestras polizas</h2>
        </div>

        <div class="col-md-6 col-lg-4 mx-lg-3 col-policy">
          <router-link class="box-policy" to="/simplepaz">
            <div class="b-image">
              <span><img src="public/images/simplepaz.svg"></span>
            </div>

            <div class="b-price">
              <div>
                <h5 class="price">$300 por lanzamiento.</h5>
                <h6 class="txt">PAGO ÚNICO</h6>
              </div>
            </div>

            <div class="b-description">
              <p>
                33 meses de protección y multi beneficios para ti y tus familiares directos por un precio inmejorable.
              </p>
            </div>

            <div class="b-extra">
              <h6 class="title">BENEFICIOS EN VIDA PROVISTOS POR:</h6>
              <p class="text-center"><img class="logo-syp" src="public/images/salud-y-paz.svg"></p>

              <div class="benefits">
                <h6 class="dental">SERVICIOS DENTALES</h6>
                <h6 class="estetica">MEDICINA ESTÉTICA</h6>
                <h6 class="visual">COBERTURA VISUAL</h6>
                <h6 class="spa">HOLÍSTICO & SPA</h6>
                <h6 class="nutricion">NUTRICIÓN</h6>
                <h6 class="psicologia">PSICOLOGÍA</h6>
              </div>
            </div>

            <div class="b-more">
              Explorar
            </div>
          </router-link>

          <!-- <p class="text-center">
            <router-link class="tos-link" to="/terminos-y-condiciones">Consulta términos y condiciones.</router-link>
          </p> -->
        </div>

        <div class="col-md-6 col-lg-4 mx-lg-3 col-policy">
          <router-link class="box-policy" to="/olimpo">
            <div class="b-image">
              <span><img src="public/images/olimpo.svg"></span>
            </div>

            <div class="b-price">
              <div>
                <h5 class="price">$4,990.00</h5>
                <h6 class="txt">PAGO ANUAL</h6>
              </div>
            </div>

            <div class="b-description">
              <p>
                Un programa pensando para los momentos más difíciles, ademas de beneficios adicionales que te permitirán vivir mejor.
              </p>
            </div>

            <div class="b-extra">
              <h6 class="title">BENEFICIOS EN VIDA PROVISTOS POR:</h6>
              <p class="text-center"><img class="logo-syp" src="public/images/salud-y-paz.svg"></p>

              <div class="benefits">
                <h6 class="dental">SERVICIOS DENTALES</h6>
                <h6 class="estetica">MEDICINA ESTÉTICA</h6>
                <h6 class="visual">COBERTURA VISUAL</h6>
                <h6 class="spa">HOLÍSTICO & SPA</h6>
                <h6 class="nutricion">NUTRICIÓN</h6>
                <h6 class="psicologia">PSICOLOGÍA</h6>
              </div>
            </div>

            <div class="b-more">
              Explorar
            </div>
          </router-link>

          <!-- <p class="text-center">
            <router-link class="tos-link" to="/terminos-y-condiciones">Consulta términos y condiciones.</router-link>
          </p> -->
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      banners: {
        desktop: [
          { imageUrl: 'public/images/pages/home/banner-1.jpg' },
          { imageUrl: 'public/images/pages/home/banner-1.jpg' },
          { imageUrl: 'public/images/pages/home/banner-1.jpg' },
        ],

        mobile: [
          { imageUrl: 'public/images/pages/home/banner-1-m.jpg' },
          { imageUrl: 'public/images/pages/home/banner-1-m.jpg' },
          { imageUrl: 'public/images/pages/home/banner-1-m.jpg' },
        ]
      },

      // == Carousel options ==
      bannersOptions: {
        effect: 'fade',
        loop: true,
        speed: 700,

        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        }
      },
      // == ==
    }
  }
}
</script>
