<template lang="html">
  <div id="policy-page">

    <section class="banner-section">
      <img src="public/images/pages/plans/simplepaz-banner-1.jpg">
    </section>

    <section class="description-section">
      <div class="container">
        <h2 class="text-center title-s2">Misión</h2>
        <p class="p-lg">
          Proporcionar a nuestros asegurados un servicio profesional en nuestras diferentes áreas de servicios de salud, con materiales de máxima calidad, aplicados con procedimientos correctos, con equipos de vanguardia y personal altamente calificado.
        </p>

        <h2 class="mt-4 text-center title-s2">Visión</h2>
        <p class="p-lg">
          Proporcionar a nuestros asegurados un servicio profesional en nuestras diferentes áreas de servicios de salud, con materiales de máxima calidad, aplicados con procedimientos correctos, con equipos de vanguardia y personal altamente calificado.
        </p>
      </div>
    </section>

    <section class="container oversized-container benefits-img-section">
      <img src="public/images/pages/plans/6-b-simplepaz.svg">
    </section>

    <section class="container benefits-section">
      <div class="row justify-content-center">
        <div class="col-12 col-title">
          <h2 class="title-s1">Beneficios</h2>
        </div>

        <div class="col-md-6 col-xl-4 col-benefit" v-for="(b, bInx) in benefits" :key="'bInx-'+bInx">
          <div class="box-benefit" @click="openBenefitModal(b)">
            <div class="placed-backg image" v-bind:style="{ backgroundImage: 'url('+b.image+')' }"></div>

            <div class="text">
              <h4>{{ b.title }}</h4>
            </div>
          </div>
        </div>
      </div>
    </section>

    <b-modal ref="my-modal" modal-class="modal-benefits" centered hide-footer no-close-on-esc no-close-on-backdrop :size="modalBenefit.size" title="">
      <div class="row">
        <div class="placed-backg col-image" v-bind:class="{ 'col-lg-4' : modalBenefit.size == 'xl', 'col-lg-6' : modalBenefit.size == 'lg', }" v-bind:style="{ backgroundImage: 'url('+modalBenefit.image+')' }"></div>

        <!-- Dentales -->
        <div class="col-lg-8 col-text" v-if="modalBenefit.selected == 'dentales'">
          <h2 class="title">Servicios dentales</h2>

          <div class="row">
            <div class="col-lg-6">
              <h5 class="subtitle">Rehabilitación</h5>
              <ul>
                <li>Limpiezas con ultrasonido</li>
                <li>Implantes</li>
                <li>Extracciones</li>
                <li>Placas completas</li>
                <li>Coronas de zirconio</li>
                <li>Resinas</li>
              </ul>

              <h5 class="mt-3 subtitle">Odontopediatría</h5>
              <ul>
                <li>Atención a niños</li>
              </ul>
            </div>

            <div class="col-lg-6 mt-3 mt-md-0">
              <h5 class="subtitle">Ortodoncia</h5>
              <ul>
                <li>Brackets</li>
              </ul>

              <h5 class="mt-3 subtitle">Periodoncia</h5>

              <h5 class="mt-3 subtitle">Cirugía Maxilofacial</h5>
              <ul>
                <li>Radiografías digitalizadas</li>
                <li>Fotografías intraorales</li>
                <li>Tratamiento avanzado con Botox</li>
                <li>Cirugías</li>
                <li>Prótesis flexibles</li>
                <li>Brackets</li>
              </ul>
            </div>
          </div>
        </div>
        <!--  -->

        <!-- Estéticos -->
        <div class="col-lg-8 col-text" v-if="modalBenefit.selected == 'esteticos'">
          <h2 class="title">Servicios medico esteticos</h2>

          <div class="row">
            <div class="col-lg-6">
              <ul>
                <li>Carillas dentales Sonrisa perfecta</li>
                <li>Bichetomias</li>
                <li>Lipopapada (Cirugía)</li>
              </ul>

              <h5 class="mt-3 subtitle">Toxina botulínica</h5>
              <ul>
                <li>Eliminación de Patas de Gallo</li>
                <li>Foxy eyes (Ceja de las Cardashians)</li>
              </ul>

              <h5 class="mt-3 subtitle">Eliminación de celulitis</h5>
            </div>

            <div class="col-lg-6 mt-3 mt-md-0">
              <h5 class="subtitle">Rellenos con Ácido Hialuronico</h5>
              <ul>
                <li>Eliminación de surcos Naso genianos</li>
                <li>Relleno y perfilamiento de labios</li>
                <li>Russian Lips</li>
                <li>Perfilamiento Mandibular</li>
                <li>Armonización Facial</li>
              </ul>

              <h5 class="mt-3 subtitle">Aumento de Glúteos</h5>
              <ul>
                <li>Peptonas Linfar de Grado Medico</li>
                <li>Electroestimulación</li>
                <li>Vacuum Grado medico</li>
              </ul>
            </div>
          </div>
        </div>
        <!--  -->

        <!-- Faciales -->
        <div class="col-lg-6 col-text" v-if="modalBenefit.selected == 'faciales'">
          <h2 class="title">Tratamientos faciales</h2>

          <div class="row align-items-center">
            <div class="col-lg-12">
              <ul>
                <li>Hidra facial</li>
                <li>Antiaging</li>
                <li>Peeling Químicos</li>
                <li>Peeling Mecánico</li>
                <li>Peeling Laser</li>
                <li>Dentox</li>
                <li>Hollywood Peel (Yag Laser Grado medico)</li>
                <li>Micropunción ( Dermapen )</li>
                <li>Lifting Facial Radiofrecuencia grado medico</li>
                <li>Tensado y Relleno de Arrugas</li>
              </ul>

              <h5 class="mt-3 mb-1 subtitle">DEPILACION LASER DIODO</h5>
              <p>Depilación definitiva garantizada con nuestro equipo de grado medico de ultima generación</p>
            </div>
          </div>
        </div>
        <!--  -->

        <!-- Control y tratamientos de grado médico estéticos -->
        <div class="col-lg-6 col-text" v-if="modalBenefit.selected == 'tratamientos-esteticos'">
          <h2 class="title">Control y tratamientos de grado médico estéticos</h2>

          <div class="row align-items-center">
            <div class="col-lg-12">
              <ul>
                <li>Eliminación de manchas</li>
                <li>Control y eliminación de acné</li>
                <li>Eliminación de tatuajes</li>
                <li>Eliminación de estrías</li>
                <li>Eliminación de cicatrices</li>
              </ul>
            </div>
          </div>
        </div>
        <!--  -->

        <!-- Terapia psicológica -->
        <div class="col-lg-6 col-text" v-if="modalBenefit.selected == 'psicologica'">
          <h2 class="title">Terapia psicológica</h2>

          <div class="row align-items-center">
            <div class="col-lg-12">
              <ul>
                <li>Tanatología</li>
                <li>Terapia Emocional</li>
                <li>Terapia adolescentes y Infantil</li>
                <li>Terapia familiar</li>
                <li>Terapia de pareja</li>
              </ul>
            </div>
          </div>
        </div>
        <!--  -->

        <!-- Reducción de tallas con aparatología -->
        <div class="col-lg-6 col-text" v-if="modalBenefit.selected == 'reduccion-tallas'">
          <h2 class="title">Reducción de tallas con aparatología</h2>

          <div class="row align-items-center">
            <div class="col-lg-12">
              <ul>
                <li>Lipo-laser</li>
                <li>Radiofrecuencia</li>
                <li>Madero terapia</li>
                <li>Drenaje Linfático brasileño</li>
                <li>Cavitación</li>
                <li>Vacuum grado médico</li>
              </ul>
            </div>
          </div>
        </div>
        <!--  -->

        <!-- Nutricion clinica -->
        <div class="col-lg-6 col-text" v-if="modalBenefit.selected == 'nutricion'">
          <h2 class="title">Nutricion clinica</h2>

          <div class="row align-items-center">
            <div class="col-lg-12">
              <ul>
                <li>Consulta nutricional</li>
                <li>Control y reducción de peso</li>
              </ul>

              <h5 class="mt-3 subtitle">Tratamiento nutricional en patologías</h5>
              <ul>
                <li>Obesidad</li>
                <li>Hipertensión</li>
                <li>Dislipidemias</li>
                <li>Diabetes</li>
                <li>Enfermedades digestivas</li>
              </ul>
            </div>
          </div>
        </div>
        <!--  -->

        <!-- Tratamientos corporales -->
        <div class="col-lg-6 col-text" v-if="modalBenefit.selected == 'tratamientos-corporales'">
          <h2 class="title">Tratamientos corporales</h2>

          <div class="row align-items-center">
            <div class="col-lg-12">
              <ul>
                <li>Electroestimulación</li>
                <li>Moldeadores</li>
                <li>Reductivos</li>
                <li>Maderoterapia</li>
                <li>Anticeluliticos</li>
                <li>Reafirmantes</li>
                <li>Drenantes</li>
                <li>Envolturas</li>
                <li>Cavitación</li>
              </ul>
            </div>
          </div>
        </div>
        <!--  -->

        <!-- Tratamientos holísticos -->
        <div class="col-lg-6 col-text" v-if="modalBenefit.selected == 'holisticos'">
          <h2 class="title">Tratamientos holísticos</h2>

          <div class="row align-items-center">
            <div class="col-lg-12">
              <ul>
                <li>Masaje Relajantes</li>
                <li>Masaje a 4 manos</li>
                <li>Masaje en Parejas</li>
                <li>Masaje en Tejido Profundo</li>
                <li>Masaje Sueco</li>
                <li>Masaje en Manos</li>
                <li>Aromaterapia</li>
                <li>Fototerapia</li>
              </ul>
            </div>
          </div>
        </div>
        <!--  -->

        <!-- Servicios ópticos -->
        <div class="col-lg-6 col-text" v-if="modalBenefit.selected == 'opticos'">
          <h2 class="title">Servicios ópticos</h2>

          <div class="row align-items-center">
            <div class="col-lg-12">
              <ul>
                <li>Examen de la vista por computadora</li>
                <li>Examen de la vista con lentillas y fotoroptor</li>
                <li>Lentes de contacto ( pupilentes )</li>
                <li>Armazones económicos</li>
                <li>Armazones de alta calidad</li>
              </ul>
            </div>
          </div>
        </div>
        <!--  -->
      </div>
    </b-modal>

  </div>
</template>

<script>
export default {
  data() {
    return {
      benefits: [
        { size: 'xl', title: 'Servicios dentales', image: 'public/images/pages/plans/dentales.jpg', selected: 'dentales' },
        { size: 'xl', title: 'Servicios medico esteticos', image: 'public/images/pages/plans/esteticos.jpg', selected: 'esteticos' },
        { size: 'lg', title: 'Tratamientos faciales', image: 'public/images/pages/plans/faciales.jpg', selected: 'faciales' },
        { size: 'lg', title: 'Control y tratamientos de grado médico estéticos', image: 'public/images/pages/plans/tratamientos-esteticos.jpg', selected: 'tratamientos-esteticos' },
        { size: 'lg', title: 'Terapia psicológica', image: 'public/images/pages/plans/psicologica.jpg', selected: 'psicologica' },
        { size: 'lg', title: 'Reducción de tallas con aparatología', image: 'public/images/pages/plans/reduccion-tallas.jpg', selected: 'reduccion-tallas' },
        { size: 'lg', title: 'Nutricion clinica', image: 'public/images/pages/plans/nutricion.jpg', selected: 'nutricion' },
        { size: 'lg', title: 'Tratamientos corporales', image: 'public/images/pages/plans/tratamientos-corporales.jpg', selected: 'tratamientos-corporales' },
        { size: 'lg', title: 'Tratamientos holísticos', image: 'public/images/pages/plans/holisticos.jpg', selected: 'holisticos' },
        { size: 'lg', title: 'Servicios ópticos', image: 'public/images/pages/plans/opticos.jpg', selected: 'opticos' },
      ],

      modalBenefit: {
        size: 'xl',
        title: null,
        image: null,
        selected: null,
      },
    }
  },

  methods: {
    openBenefitModal(benefit) {
      this.modalBenefit = benefit;

      this.$refs['my-modal'].show();
    }
  },

  mounted() {

  }
}
</script>
