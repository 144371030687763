<template lang="html">
  <footer id="footer" v-if="false">

		<section class="section-1">
      <div class="container">
    		<div class="row">
  				<div class="col-12 col-lg-4 col-footer col-logo">
            <img class="mb-2 logo" src="public/images/lapaz-white.svg" alt="">
  					<p class="mb-1 pl-p">
              <router-link to="/aviso-de-privacidad"><u>Aviso de Privacidad</u></router-link>
  					</p>
            <!-- <p class="pl-p">
              <router-link to="/terminos-y-condiciones"><u>Términos y condiciones</u></router-link>
            </p> -->
  				</div>
  				<div class="col-md-6 col-lg-4 col-footer">
  					<p>
  						<i class="fas fa-phone-alt"></i> <span>(387) 763-1730 & (376) 768-2352</span>
  					</p>
            <p class="my-2">
  						<i class="fas fa-phone-alt"></i> <span>Lada sin costo. 01 800 890 9070</span>
  					</p>
  					<p class="my-2">
  						<i class="fas fa-envelope"></i> <span>clientes@lapazprevisionfamiliar.com</span>
  					</p>

            <!-- <p class="mb-1">
              <i class="fas fa-map-marker-alt"></i> <span>Juarez Norte 29, Centro, Tlajomulco de Zúñiga, Jalisco.</span><br />
  					</p>
            <p>
              <i class="fas fa-map-marker-alt"></i> <span>Vicente Guerrero No.152, Guadalajara, Jalisco.</span><br />
            </p> -->
  				</div>
  				<div class="col-md-6 col-lg-4 col-footer">
            <p class="mb-2 p-sm">
              Un funeral es un momento para honrar la vida de los difuntos y celebrar el legado de su familia. Nos esforzamos para que cada funeral sea una experiencia respetuosa y satisfactoria que cubra las necesidades únicas de cada familia.
            </p>
            <p class="p-sm">
              Nuestras instalaciones proveen de todos los servicios necesarios, comodidad y ubicación conveniente.
            </p>

            <p class="mt-2">
              <a class="mr-1" target="_blank" href="https://www.facebook.com/Lapazprevisionfamiliar/">
                <i class="fab fa-facebook-square icon"></i>
              </a>
              <a class="ml-1" target="_blank" href="https://www.instagram.com/pazprevision/?igshid=YmMyMTA2M2Y%3D">
                <i class="fab fa-instagram icon"></i>
              </a>
            </p>
  				</div>
    		</div>
    	</div>
    </section>

		<section class="section-2">
      <div class="container oversized-container">
        <div class="row justify-content-center">
          <div class="col-sm-6 col-lg col-location">
            <h5 class="loc-name">TLAJOMULCO</h5>
            <h6 class="mb-1">Centro Integral de apoyo La Paz Previsión Familiar</h6>
            <p>Juarez Norte 29<br />Col. Centro Tlajomulco de Zúñiga<br /> a un lado del templo blanco</p>
            <p>Tel. ‎33 3801 4471 & ‎33 2733 4542</p>
          </div>

          <div class="col-sm-6 col-lg col-location">
            <h5 class="mb-1 loc-name">GUADALAJARA</h5>
            <p>Av. Circunvalación División del Norte #1548<br />Col. Jardines del Country<br />C.P. 44210 Guadalajara, Jal.</p>
          </div>

          <div class="col-sm-6 col-lg col-location">
            <h5 class="mb-1 loc-name">ZAPOPAN</h5>
            <p>Av. Ludwig Van Beethoven #5104,<br />La Estancia, Zapopan, Jal. C.P. 45030</p>
          </div>

          <div class="col-sm-6 col-lg col-location">
            <h5 class="mb-1 loc-name">CELAYA</h5>
            <p>Hidalgo #402, Esquina con Antonio Chairas<br />Zona Centro, Celaya, Gto.<br />C.P. 38050</p>
          </div>

          <div class="col-sm-6 col-lg col-location">
            <h5 class="loc-name">CENTRO MÉDICO MEDAC</h5>
            <h6 class="mb-1">Centro Integral de apoyo La Paz Previsión Familiar</h6>
            <p>Av. Vía de la Juventud #8800-108<br />Tijuana, Baja California.</p>
          </div>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
export default {
}
</script>
