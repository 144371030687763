import { render, staticRenderFns } from "./simplepaz.vue?vue&type=template&id=4daa7a21&lang=html&"
import script from "./simplepaz.vue?vue&type=script&lang=js&"
export * from "./simplepaz.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports